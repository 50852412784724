import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaTiktok.module.css';

const IconSocialMediaTiktok = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="20" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
    <defs>
      <style>{".cls-1{fill:#25f4ee}.cls-2{fill:#fe2c55}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <circle cx={300} cy={300} r={300} id="BACKGROUND" />
      <g id="ICONS">
        <path
          className="cls-1"
          d="M260.18 257.1v-16.28a123.43 123.43 0 0 0-87.44 223.47 123.46 123.46 0 0 1 87.44-207.19Z"
        />
        <path
          className="cls-1"
          d="M263.09 436.94c30.4 0 55.54-24.52 56.67-54.64l.24-268.59h48.7l-.07-.41a92.76 92.76 0 0 1-1.59-17H300l-.11 269a56.29 56.29 0 0 1-82.48 47.81c10.21 14.18 26.86 23.83 45.68 23.83ZM460.44 205.42v-15.76a92.77 92.77 0 0 1-50.85-15.12c13.04 14.98 30.75 26.58 50.85 30.88Z"
        />
        <path
          className="cls-2"
          d="M409.59 174.54a92.9 92.9 0 0 1-23-61.22h-18.25a94 94 0 0 0 41.25 61.22ZM243.53 306.21c-31.11 0-56.79 25.83-56.79 56.93 0 21.67 12.66 40.49 30.62 49.94a56.32 56.32 0 0 1 45.73-89.32 55.25 55.25 0 0 1 16.65 2.61v-68.52a123.46 123.46 0 0 0-16.65-1.21c-1 0-1.94.05-2.91.08h-.26l-.18 51.86a52.61 52.61 0 0 0-16.21-2.37Z"
        />
        <path
          className="cls-2"
          d="m460.44 204.62-.32-.06v51.82A160.17 160.17 0 0 1 366.65 226v137.11a123.51 123.51 0 0 1-193.91 101.18 123.45 123.45 0 0 0 213.89-84.11v-136.4a159.56 159.56 0 0 0 93.37 30V206.7a93.39 93.39 0 0 1-19.56-2.08Z"
        />
        <path
          d="M367.06 363.14V226.75a159.61 159.61 0 0 0 93.38 30v-52.13a93.45 93.45 0 0 1-50.85-30.08 93.51 93.51 0 0 1-40.94-61.22h-49.14l-.11 269a56.31 56.31 0 0 1-102 30.78 56.39 56.39 0 0 1 26.17-106.35 55.56 55.56 0 0 1 16.65 2.6v-52.63c-66.81 1.56-120.66 56.28-120.66 123.46a123.05 123.05 0 0 0 33.18 84.11 123.43 123.43 0 0 0 194.32-101.15Z"
          style={{
            fill: "#fff",
          }}
        />
      </g>
    </g>
  </svg>
  );
};

IconSocialMediaTiktok.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaTiktok.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaTiktok;
