import React from 'react';
import ReactTooltip from 'react-tooltip';
import { GoInfo } from 'react-icons/go';
import css from './InfoTooltip.module.css';

export default function InfoTooltip({ id, children, propStyle }) {
  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  const iconStyle = {
    height: '20px',
    width: '20px',
  };

  const style = {
    paddingLeft: '5px',
  };

  return (
    <div className={css.container}>
      <a data-tip data-for={id} style={style}>
        <GoInfo style={propStyle ? propStyle : iconStyle} />
      </a>
      <ReactTooltip id={id} className={css.tooltipStyle}>
        <span>{children}</span>
      </ReactTooltip>
    </div>
  );
}
