/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
export const orderOptions = [
  { label: 'Shipping', value: 'shipping', key: 'shipping' },
  { label: 'Pickup', value: 'pickup', key: 'pickup' },
];
export const genderOptions = [
  { label: 'Mens', value: 'mens', key: 'mens' },
  { label: 'Womens', value: 'womens', key: 'womens' },
  { label: 'Kids', value: 'kids', key: 'kids' },
];
export const womenCategoriesOptions = [
  { label: 'Tops', value: 'women_tops', key: 'women_tops' },
  { label: 'Bottoms', value: 'women_bottoms', key: 'women_bottoms' },
  { label: 'Dresses', value: 'women_dresses', key: 'women_dresses' },
  { label: 'Coats and Jackets', value: 'women_coats_jackets', key: 'women_coats_jackets' },
  { label: 'Jumpsuits', value: 'women_jumpsuits', key: 'women_jumpsuits' },
  { label: 'Suits', value: 'women_suits', key: 'women_suits' },
  { label: 'Footwear', value: 'women_footwear', key: 'women_footwear' },
  { label: 'Accessories', value: 'women_accessories', key: 'women_accessories' },
  { label: 'Nightwear', value: 'women_nightwear', key: 'women_nightwear' },
  { label: 'Underwear', value: 'women_underwear', key: 'women_underwear' },
  { label: 'Swimwear', value: 'women_swimwear', key: 'women_swimwear' },
  { label: 'Bags', value: 'women_bags', key: 'women_bags' },
];

export const womenTopSubCategories = [
  { label: 'T-shirts', value: 'women_t_shirts', key: 'women_t_shirts' },
  { label: 'Hoodies', value: 'women_hoodies', key: 'women_hoodies' },
  { label: 'Sweatshirts', value: 'women_sweatshirts', key: 'women_sweatshirts' },
  { label: 'Sweaters', value: 'women_sweaters', key: 'women_sweaters' },
  { label: 'Cardigans', value: 'women_cardigans', key: 'women_cardigans' },
  { label: 'Shirts', value: 'women_shirts', key: 'women_shirts' },
  { label: 'Polo shirts', value: 'women_poloshirts', key: 'women_poloshirts' },
  { label: 'Blouses', value: 'women_blouses', key: 'women_blouses' },
  { label: 'Crop tops', value: 'women_croptops', key: 'women_croptops' },
  { label: 'Vests', value: 'women_vests', key: 'women_vests' },
  { label: 'Corsets', value: 'women_corsets', key: 'women_corsets' },
  { label: 'Bodysuits', value: 'women_bodysuits', key: 'women_bodysuits' },
];

export const womenBottomSubCategories = [
  { label: 'Jeans', key: 'women_jeans', value: 'women_jeans' },
  { label: 'Sweatpants', key: 'women_sweatpants', value: 'women_sweatpants' },
  { label: 'Trousers', key: 'women_trousers', value: 'women_trousers' },
  { label: 'Shorts', key: 'women_shorts', value: 'women_shorts' },
  { label: 'Leggings', key: 'women_leggings', value: 'women_leggings' },
  { label: 'Skirts', key: 'women_skirts', value: 'women_skirts' },
  { label: 'Cocktail', key: 'women_cocktail', value: 'women_cocktail' },
  { label: 'Casual', key: 'women_casual', value: 'women_casual' },
  { label: 'Formal', key: 'women_formal', value: 'women_formal' },
];

export const womenDressSubCategories = [
  { label: 'Cocktail', key: 'women_cocktail', value: 'women_cocktail' },
  { label: 'Casual', key: 'women_casual', value: 'women_casual' },
  { label: 'Formal', key: 'women_formal', value: 'women_formal' },
];

export const womenCoatsAndJacketSubCategories = [
  { label: 'Coats', key: 'women_coats', value: 'women_coats' },
  { label: 'Jackets', key: 'women_jackets', value: 'women_jackets' },
  { label: 'Gilets', key: 'women_gilets', value: 'women_gilets' },
];

export const womenFootWearsSubCategories = [
  { label: 'Sneakers', key: 'women_sneakers', value: 'women_sneakers' },
  { label: 'Slides', key: 'women_slides', value: 'women_slides' },
  { label: 'Sandals', key: 'women_sandals', value: 'women_sandals' },
  { label: 'Flip flops', key: 'women_flip_flops', value: 'women_flip_flops' },
  { label: 'Slippers', key: 'women_slippers', value: 'women_slippers' },
  { label: 'Dress shoes', key: 'women_dress_shoes', value: 'women_dress_shoes' },
  { label: 'Boots', key: 'women_boots', value: 'women_boots' },
  { label: 'Espadrilles', key: 'women_espadrilles', value: 'women_espadrilles' },
  { label: 'Ballet shoes', key: 'women_ballet_shoes', value: 'women_ballet_shoes' },
  { label: 'Clogs', key: 'women_clogs', value: 'women_clogs' },
  { label: 'Mules', key: 'women_mules', value: 'women_mules' },
  { label: 'Heels', key: 'women_heels', value: 'women_heels' },
];
export const womenAccessoriesSubCategories = [
  { label: 'Hats and caps', key: 'women_hats_caps', value: 'women_hats_caps' },
  { label: 'Gloves', key: 'women_gloves', value: 'women_gloves' },
  { label: 'Scarves and wraps', key: 'women_scarves', value: 'women_scarves' },
  { label: 'Wallets and cardholders', key: 'women_wallets', value: 'women_wallets' },
  { label: 'Hair accessories', key: 'women_accessories', value: 'women_accessories' },
  { label: 'Belts', key: 'women_belts', value: 'women_belts' },
];

export const womenNightwareSubCategories = [
  { label: 'Pajamas', key: 'women_pajamas', value: 'women_pajamas' },
  { label: 'Robes', key: 'w_robes', value: 'w_robes' },
];
export const womenUnderwearSubCategories = [
  { label: 'Bandeaus', key: 'w_bandeaus', value: 'w_bandeaus' },
  { label: 'Bras', key: 'w_bras', value: 'w_bras' },
  { label: 'Panties', key: 'w_panties', value: 'w_panties' },
  { label: 'Shapewear', key: 'w_shapewear', value: 'w_shapewear' },
  {
    label: 'Boxers and briefs',
    key: 'w_boxers_and_briefs',
    value: 'w_boxers_and_briefs',
  },
  { label: 'Socks', key: 'w_socks', value: 'w_socks' },
];
export const womenSwimwearSubCategories = [
  {
    label: 'Bikini sets',
    key: 'w_bikini_sets',
    value: 'w_bikini_sets',
  },
  {
    label: 'Bikini tops',
    key: 'w_bikini_tops',
    value: 'w_bikini_tops',
  },
  {
    label: 'Bikini bottoms',
    key: 'w_bikini_bottoms',
    value: 'w_bikini_bottoms',
  },
  { label: 'Swimsuits', key: 'w_swimsuits', value: 'w_swimsuits' },
  { label: 'Cover ups', key: 'w_cover_ups', value: 'w_cover_ups' },
];
export const womenBagsSubCategories = [
  { label: 'Backpacks', key: 'w_backpacks', value: 'w_backpacks' },
  {
    label: 'Beach & straw bags',
    key: 'w_beach_&_straw_bags',
    value: 'w_beach_&_straw_bags',
  },
  { label: 'Belt bags', key: 'w_belt_bags', value: 'w_belt_bags' },
  {
    label: 'Bucket bags',
    key: 'w_bucket_bags',
    value: 'w_bucket_bags',
  },
  { label: 'Clutches', key: 'w_clutches', value: 'w_clutches' },
  {
    label: 'Cross body bags',
    key: 'w_cross_body_bags',
    value: 'w_cross_body_bags',
  },
  {
    label: 'Luggage & weekenders',
    key: 'w_luggage_&_weekenders',
    value: 'w_luggage_&_weekenders',
  },
  { label: 'Mini bags', key: 'w_mini_bags', value: 'w_mini_bags' },
  {
    label: 'Satchels and top handles',
    key: 'w_satchels_and_top_handles',
    value: 'w_satchels_and_top_handles',
  },
  {
    label: 'Shoulder bags',
    key: 'w_shoulder_bags',
    value: 'w_shoulder_bags',
  },
  { label: 'Totes', key: 'w_totes', value: 'w_totes' },
];
//Men category
export const menCategoriesOptions = [
  { label: 'Tops', value: 'men_tops', key: 'men_tops' },
  { label: 'Bottoms', value: 'men_bottoms', key: 'men_bottoms' },
  { label: 'Coats and Jackets', value: 'men_coats_jackets', key: 'men_coats_jackets' },
  { label: 'Jumpsuits', value: 'men_jumpsuits', key: 'men_jumpsuits' },
  { label: 'Suits', value: 'men_suits', key: 'men_suits' },
  { label: 'Footwear', value: 'men_footwear', key: 'men_footwear' },
  { label: 'Accessories', value: 'men_accessories', key: 'men_accessories' },
  { label: 'Nightwear', value: 'men_nightwear', key: 'men_nightwear' },
  { label: 'Underwear', value: 'men_underwear', key: 'men_underwear' },
  { label: 'Swimwear', value: 'men_swimwear', key: 'men_swimwear' },
  { label: 'Bags', value: 'men_bags', key: 'men_bags' },
];
export const menTopSubcategories = [
  {
    label: 'Short sleeve t-shirts',
    key: 'm_short_sleeve_t-shirts',
    value: 'm_short_sleeve_t-shirts',
  },
  { label: 'Hoodies', key: 'm_hoodies', value: 'm_hoodies' },
  {
    label: 'Sweatshirts',
    key: 'm_sweatshirts',
    value: 'm_sweatshirts',
  },
  { label: 'Sweaters', key: 'm_sweaters', value: 'm_sweaters' },
  { label: 'Cardigans', key: 'm_cardigans', value: 'm_cardigans' },
  {
    label: 'Long sleeve shirts',
    key: 'm_long_sleeve_shirts',
    value: 'm_long_sleeve_shirts',
  },
  {
    label: 'Polo shirts',
    key: 'm_polo_shirts',
    value: 'm_polo_shirts',
  },
  {
    label: 'Short sleeve shirts',
    key: 'm_short_sleeve_shirts',
    value: 'm_short_sleeve_shirts',
  },
  {
    label: 'Long sleeve t-shirts',
    key: 'm_long_sleeve_t-shirts',
    value: 'm_long_sleeve_t-shirts',
  },
];
export const menBottomsSubcategories = [
  { label: 'Jeans', key: 'm_jeans', value: 'm_jeans' },
  { label: 'Sweatpants', key: 'm_sweatpants', value: 'm_sweatpants' },
  { label: 'Trousers', key: 'm_trousers', value: 'm_trousers' },
  { label: 'Shorts', key: 'm_shorts', value: 'm_shorts' },
];
export const menCoatsAndJacketsSubcategories = [
  { label: 'Coats', key: 'm_coats', value: 'm_coats' },
  { label: 'Jackets', key: 'm_jackets', value: 'm_jackets' },
  { label: 'Vests', key: 'm_vests', value: 'm_vests' },
];
export const menFootwearSubcategories = [
  { label: 'Sneakers', key: 'm_sneakers', value: 'm_sneakers' },
  { label: 'Slides', key: 'm_slides', value: 'm_slides' },
  { label: 'Sandals', key: 'm_sandals', value: 'm_sandals' },
  { label: 'Flip flops', key: 'm_flip_flops', value: 'm_flip_flops' },
  { label: 'Slippers', key: 'm_slippers', value: 'm_slippers' },
  {
    label: 'Dress shoes',
    key: 'm_dress_shoes',
    value: 'm_dress_shoes',
  },
  { label: 'Boots', key: 'm_boots', value: 'm_boots' },
  { label: 'Soft shoes', key: 'm_soft_shoes', value: 'm_soft_shoes' },
  {
    label: 'Espadrilles',
    key: 'm_espadrilles',
    value: 'm_espadrilles',
  },
  { label: 'Clogs', key: 'm_clogs', value: 'm_clogs' },
  { label: 'Mules', key: 'm_mules', value: 'm_mules' },
];
export const menAccessoriesSubcategories = [
  {
    label: 'Hats and caps',
    key: 'm_hats_and_caps',
    value: 'm_hats_and_caps',
  },
  { label: 'Gloves', key: 'm_gloves', value: 'm_gloves' },
  {
    label: 'Scarves and wraps',
    key: 'm_scarves_and_wraps',
    value: 'm_scarves_and_wraps',
  },
  {
    label: 'Wallets and cardholders',
    key: 'm_wallets_and_cardholders',
    value: 'm_wallets_and_cardholders',
  },
  {
    label: 'Hair accessories',
    key: 'm_hair_accessories',
    value: 'm_hair_accessories',
  },
  { label: 'Belts', key: 'm_belts', value: 'm_belts' },
];

export const menNightwearSubcategories = [
  { label: 'Pajamas', key: 'm_pajamas', value: 'm_pajamas' },
  { label: 'Robes', key: 'm_robes', value: 'm_robes' },
];
export const menUnderwearSubcategories = [
  { label: 'Boxers', key: 'm_boxers', value: 'm_boxers' },
  { label: 'Briefs', key: 'm_briefs', value: 'm_briefs' },
  { label: 'Socks', key: 'm_socks', value: 'm_socks' },
];
export const menSwimwearSubcategories = [
  {
    label: 'Swim shorts',
    key: 'm_swim_shorts',
    value: 'm_swim_shorts',
  },
  {
    label: 'Swim briefs',
    key: 'm_swim_briefs',
    value: 'm_swim_briefs',
  },
];
export const menBagsSubcategories = [
  { label: 'Backpacks', key: 'm_backpacks', value: 'm_backpacks' },
  { label: 'Belt bags', key: 'm_belt_bags', value: 'm_belt_bags' },
  { label: 'Briefcases', key: 'm_briefcases', value: 'm_briefcases' },
  { label: 'Duffels', key: 'm_duffels', value: 'm_duffels' },
  { label: 'Luggage', key: 'm_luggage', value: 'm_luggage' },
  { label: 'Messengers', key: 'm_messengers', value: 'm_messengers' },
  { label: 'Pouches', key: 'm_pouches', value: 'm_pouches' },
  { label: 'Totes', key: 'm_totes', value: 'm_totes' },
];
//kids categories
export const kidsCategoriesOptions = [
  { label: 'Baby girl 0-24m', value: 'baby_girl_0_24', key: 'baby_girl_0_24' },
  { label: 'Baby boy 0-24m', value: 'baby_boy_0_24', key: 'baby_boy_0_24' },
  { label: 'Toddler girl 2-6', value: 'toddler_girl_2_6', key: 'toddler_girl_2_6' },
  { label: 'Toddler boy 2-6', value: 'toddler_boy_2_6', key: 'toddler_boy_2_6' },
  { label: 'Girl youth 7-12', value: 'girl_youth_7_12', key: 'girl_youth_7_12' },
  { label: 'Boy youth 7-12', value: 'boy_youth_7_12', key: 'boy_youth_7_12' },
  { label: 'Girl teen 13-16', value: 'girl_teen_13_16', key: 'girl_teen_13_16' },
  { label: 'Boy teen 13-16', value: 'boy_teen_13_16', key: 'boy_teen_13_16' },
];
//Baby girl 0-24m
export const babyGirlSubcategories = [
  {
    label: 'Accessories',
    key: 'kg_accessories',
    value: 'kg_accessories',
  },
  { label: 'Bottoms', key: 'kg_bottoms', value: 'kg_bottoms' },
  { label: 'Tops', key: 'kg_tops', value: 'kg_tops' },
  { label: 'Jumpsuits', key: 'kg_jumpsuits', value: 'kg_jumpsuits' },
  { label: 'Shoes', key: 'kg_shoes', value: 'kg_shoes' },
  { label: 'Dresses', key: 'kg_dresses', value: 'kg_dresses' },
];

//Baby boy 0-24m
export const babyBoySubcategories = [
  {
    label: 'Accessories',
    key: 'kb_accessories',
    value: 'kb_accessories',
  },
  { label: 'Bottoms', key: 'kb_bottoms', value: 'kb_bottoms' },
  { label: 'Tops', key: 'kb_tops', value: 'kb_tops' },
  { label: 'Jumpsuits', key: 'kb_jumpsuits', value: 'kb_jumpsuits' },
  { label: 'Shoes', key: 'kb_shoes', value: 'kb_shoes' },
];
// Toddler girl 2-6
export const toddlerGirlSubcategories = [
  {
    label: 'Accessories',
    key: 'ktg_accessories',
    value: 'ktg_accessories',
  },
  { label: 'Bottoms', key: 'ktg_bottoms', value: 'ktg_bottoms' },
  { label: 'Tops', key: 'ktg_tops', value: 'ktg_tops' },
  { label: 'Jumpsuits', key: 'ktg_jumpsuits', value: 'ktg_jumpsuits' },
  { label: 'Shoes', key: 'ktg_shoes', value: 'ktg_shoes' },
  { label: 'Dresses', key: 'ktg_dresses', value: 'ktg_dresses' },
];
// Toddler boy 2-6
export const toddlerBoySubcategories = [
  {
    label: 'Accessories',
    key: 'ktb_accessories',
    value: 'ktb_accessories',
  },
  { label: 'Bottoms', key: 'ktb_bottoms', value: 'ktb_bottoms' },
  { label: 'Tops', key: 'ktb_tops', value: 'ktb_tops' },
  { label: 'Jumpsuits', key: 'ktb_jumpsuits', value: 'ktb_jumpsuits' },
  { label: 'Shoes', key: 'ktb_shoes', value: 'ktb_shoes' },
];
// Girl youth 7-12
export const girlYouthSubcategories = [
  {
    label: 'Accessories',
    key: 'kyg_accessories',
    value: 'kyg_accessories',
  },
  { label: 'Bottoms', key: 'kyg_bottoms', value: 'kyg_bottoms' },
  { label: 'Tops', key: 'kyg_tops', value: 'kyg_tops' },
  { label: 'Jumpsuits', key: 'kyg_jumpsuits', value: 'kyg_jumpsuits' },
  { label: 'Shoes', key: 'kyg_shoes', value: 'kyg_shoes' },
];
// Boy youth 7-12
export const boyYouthSubcategories = [
  {
    label: 'Accessories',
    key: 'kyb_accessories',
    value: 'kyb_accessories',
  },
  { label: 'Bottoms', key: 'kyb_bottoms', value: 'kyb_bottoms' },
  { label: 'Tops', key: 'kyb_tops', value: 'kyb_tops' },
  { label: 'Jumpsuits', key: 'kyb_jumpsuits', value: 'kyb_jumpsuits' },
  { label: 'Shoes', key: 'kyb_shoes', value: 'kyb_shoes' },
];
// Girl teen 13-16
export const girlTeenSubcategories = [
  {
    label: 'Accessories',
    key: 'ktg_accessories',
    value: 'ktg_accessories',
  },
  { label: 'Bottoms', key: 'ktg_bottoms', value: 'ktg_bottoms' },
  { label: 'Tops', key: 'ktg_tops', value: 'ktg_tops' },
  { label: 'Jumpsuits', key: 'ktg_jumpsuits', value: 'ktg_jumpsuits' },
  { label: 'Shoes', key: 'ktg_shoes', value: 'ktg_shoes' },
];
// Boy teen 13-16
export const boyTeenSubcategories = [
  {
    label: 'Accessories',
    key: 'ktb_accessories',
    value: 'ktb_accessories',
  },
  { label: 'Bottoms', key: 'ktb_bottoms', value: 'ktb_bottoms' },
  { label: 'Tops', key: 'ktb_tops', value: 'ktb_tops' },
  { label: 'Jumpsuits', key: 'ktb_jumpsuits', value: 'ktb_jumpsuits' },
  { label: 'Shoes', key: 'ktb_shoes', value: 'ktb_shoes' },
];

//Generic size
export const genericSize = [
  { label: 'XXS', key: 'xxs', value: 'xxs', type: 'g', id: 1 },
  { label: 'XS', key: 'xs', value: 'xs', type: 'g', id: 2 },
  { label: 'S', key: 's', value: 's', type: 'g', id: 3 },
  { label: 'M', key: 'm', value: 'm', type: 'g', id: 4 },
  { label: 'L', key: 'l', value: 'l', type: 'g', id: 5 },
  { label: 'XL', key: 'xl', value: 'xl', type: 'g', id: 6 },
  { label: 'XXL', key: 'xxl', value: 'xxl', type: 'g', id: 7 },
  { label: 'XXXL', key: 'xxxl', value: 'xxxl', type: 'g', id: 8 },
  { label: 'XXXXL', key: 'xxxxl', value: 'xxxxl', type: 'g', id: 9 },
];
//footwear
export const footwearMenSize = [
  { label: 3.5, key: 'm_3.5', value: 'm_3.5', type: 'fm', id: 1 },
  { label: 4, key: 'm_4', value: 'm_4', type: 'fm', id: 2 },
  { label: 4.5, key: 'm_4.5', value: 'm_4.5', type: 'fm', id: 3 },
  { label: 5, key: 'm_5', value: 'm_5', type: 'fm', id: 4 },
  { label: 5.5, key: 'm_5.5', value: 'm_5.5', type: 'fm', id: 5 },
  { label: 6, key: 'm_6', value: 'm_6', type: 'fm', id: 6 },
  { label: 6.5, key: 'm_6.5', value: 'm_6.5', type: 'fm', id: 7 },
  { label: 7, key: 'm_7', value: 'm_7', type: 'fm', id: 8 },
  { label: 7.5, key: 'm_7.5', value: 'm_7.5', type: 'fm', id: 9 },
  { label: 8, key: 'm_8', value: 'm_8', type: 'fm', id: 10 },
  { label: 8.5, key: 'm_8.5', value: 'm_8.5', type: 'fm', id: 11 },
  { label: 9, key: 'm_9', value: 'm_9', type: 'fm', id: 12 },
  { label: 3, key: 'm_3', value: 'm_3', type: 'fm', id: 13 },
  { label: 9.5, key: 'm_9.5', value: 'm_9.5', type: 'fm', id: 14 },
  { label: 10, key: 'm_10', value: 'm_10', type: 'fm', id: 15 },
  { label: 10.5, key: 'm_10.5', value: 'm_10.5', type: 'fm', id: 16 },
  { label: 11, key: 'm_11', value: 'm_11', type: 'fm', id: 17 },
  { label: 11.5, key: 'm_11.5', value: 'm_11.5', type: 'fm', id: 18 },
  { label: 12, key: 'm_12', value: 'm_12', type: 'fm', id: 19 },
  { label: 12.5, key: 'm_12.5', value: 'm_12.5', type: 'fm', id: 20 },
  { label: 13, key: 'm_13', value: 'm_13', type: 'fm', id: 21 },
  { label: 13.5, key: 'm_13.5', value: 'm_13.5', type: 'fm', id: 22 },
  { label: 14, key: 'm_14', value: 'm_14', type: 'fm', id: 23 },
  { label: 14.5, key: 'm_14.5', value: 'm_14.5', type: 'fm', id: 24 },
  { label: 15, key: 'm_15', value: 'm_15', type: 'fm', id: 25 },
  { label: 15.5, key: 'm_15.5', value: 'm_15.5', type: 'fm', id: 26 },
];
export const footwearWomenSize = [
  { label: 4.5, key: 'w_4.5', value: 'w_4.5', type: 'fw', id: 1 },
  { label: 5, key: 'w_5', value: 'w_5', type: 'fw', id: 2 },
  { label: 5.5, key: 'w_5.5', value: 'w_5.5', type: 'fw', id: 3 },
  { label: 6, key: 'w_6', value: 'w_6', type: 'fw', id: 4 },
  { label: 6.5, key: 'w_6.5', value: 'w_6.5', type: 'fw', id: 5 },
  { label: 7, key: 'w_7', value: 'w_7', type: 'fw', id: 6 },
  { label: 7.5, key: 'w_7.5', value: 'w_7.5', type: 'fw', id: 7 },
  { label: 8, key: 'w_8', value: 'w_8', type: 'fw', id: 8 },
  { label: 8.5, key: 'w_8.5', value: 'w_8.5', type: 'fw', id: 9 },
  { label: 9, key: 'w_9', value: 'w_9', type: 'fw', id: 10 },
  { label: 9.5, key: 'w_9.5', value: 'w_9.5', type: 'fw', id: 11 },
  { label: 10, key: 'w_10', value: 'w_10', type: 'fw', id: 12 },
  { label: 10.5, key: 'w_10.5', value: 'w_10.5', type: 'fw', id: 13 },
  { label: 11, key: 'w_11', value: 'w_11', type: 'fw', id: 14 },
  { label: 11.5, key: 'w_11.5', value: 'w_11.5', type: 'fw', id: 15 },
  { label: 12, key: 'w_12', value: 'w_12', type: 'fw', id: 16 },
  { label: 12.5, key: 'w_12.5', value: 'w_12.5', type: 'fw', id: 17 },
  { label: 13, key: 'w_13', value: 'w_13', type: 'fw', id: 18 },
  { label: 13.5, key: 'w_13.5', value: 'w_13.5', type: 'fw', id: 19 },
  { label: 14, key: 'w_14', value: 'w_14', type: 'fw', id: 20 },
  { label: 14.5, key: 'w_14.5', value: 'w_14.5', type: 'fw', id: 21 },
  { label: 15, key: 'w_15', value: 'w_15', type: 'fw', id: 22 },
  { label: 15.5, key: 'w_15.5', value: 'w_15.5', type: 'fw', id: 23 },
  { label: 16, key: 'w_16', value: 'w_16', type: 'fw', id: 24 },
  { label: 16.5, key: 'w_16.5', value: 'w_16.5', type: 'fw', id: 25 },
  { label: 17, key: 'w_17', value: 'w_17', type: 'fw', id: 26 },
];
//0-24m
export const size0_24m = [
  { label: 'Newborn', key: 'w_Newborn', value: 'w_Newborn', type: '0_24m', id: 1 },
  { label: '3m', key: 'w_3m', value: 'w_3m', type: '0_24m', id: 2 },
  { label: '6m', key: 'w_6m', value: 'w_6m', type: '0_24m', id: 3 },
  { label: '9m', key: 'w_9m', value: 'w_9m', type: '0_24m', id: 4 },
  { label: '12m', key: 'w_12m', value: 'w_12m', type: '0_24m', id: 5 },
  { label: '18m', key: 'w_18m', value: 'w_18m', type: '0_24m', id: 6 },
  { label: '24m', key: 'w_24m', value: 'w_24m', type: '0_24m', id: 7 },
];
//toddler 2-6
export const toddler2_6 = [
  { label: '2T', key: '2T', value: '2T', type: 'toddler2_6', id: 1 },
  { label: '3T', key: '3T', value: '3T', type: 'toddler2_6', id: 2 },
  { label: '4T', key: '4T', value: '4T', type: 'toddler2_6', id: 3 },
  { label: '5T', key: '5T', value: '5T', type: 'toddler2_6', id: 4 },
  { label: '6', key: '6', value: '6', type: 'toddler2_6', id: 5 },
  { label: '6X', key: '6X', value: '6X', type: 'toddler2_6', id: 6 },
  { label: '4', key: '4', value: '4', type: 'toddler2_6', id: 7 },
];
//size 4 to 16
export const size4_16 = [
  { label: '4', key: 's_4', value: 's_4', type: '4_16', id: 1 },
  { label: '5', key: '5', value: '5', type: '4_16', id: 2 },
  { label: '6', key: '6', value: '6', type: '4_16', id: 3 },
  { label: '6X', key: '6X', value: '6X', type: '4_16', id: 4 },
  { label: '7', key: '7', value: '7', type: '4_16', id: 5 },
  { label: '8', key: '8', value: '8', type: '4_16', id: 6 },
  { label: '10', key: '10', value: '10', type: '4_16', id: 7 },
  { label: '12', key: '12', value: '12', type: '4_16', id: 8 },
  { label: '14', key: '14', value: '14', type: '4_16', id: 9 },
  { label: '16', key: '16', value: '16', type: '4_16', id: 10 },
];

// export const sizeOptions = [
//   { label: 'XS', value: 'xs', key: 'xs' },
//   { label: 'S', value: 's', key: 's' },
//   { label: 'M', value: 'm', key: 'm' },
//   { label: 'L', value: 'l', key: 'l' },
//   { label: 'XL', value: 'xl', key: 'xl' },
//   { label: 'XXL', value: 'xxl', key: 'xxl' },
//   { label: 'One size', value: 'one_size', key: 'one_size' },
// ];

export const colorOptions = [
  { label: 'Burgundy', value: 'burgundy', key: 'burgundy', color: '#8c2d19', id: 1 },
  { label: 'Black', value: 'black', key: 'black', color: '#000000', id: 2 },
  { label: 'Blue', value: 'blue', key: 'blue', color: '#00a1e0', id: 3 },
  { label: 'Brown', value: 'brown', key: 'brown', color: '#cb6d51', id: 4 },
  { label: 'Cream', value: 'cream', key: 'cream', color: '#f5e1aa', id: 5 },
  { label: 'Grey', value: 'grey', key: 'grey', color: '#808080', id: 6 },
  { label: 'Green', value: 'green', key: 'green', color: '#00a848', id: 7 },
  { label: 'Gold', value: 'gold', key: 'gold', color: '#ffd700', id: 8 },
  { label: 'Khaki', value: 'khaki', key: 'khaki', color: '#c3b091', id: 9 },
  { label: 'Multi', value: 'multi', key: 'multi', color: 'multi', id: 10 },
  { label: 'Navy', value: 'navy', key: 'navy', color: '#003087', id: 11 },
  { label: 'Orange', value: 'orange', key: 'orange', color: '#f9a825', id: 12 },
  { label: 'Pink', value: 'pink', key: 'pink', color: '#f9c0c0', id: 13 },
  { label: 'Purple', value: 'purple', key: 'purple', color: '#9b51e0', id: 14 },
  { label: 'Red', value: 'red', key: 'red', color: '#e84855', id: 15 },
  { label: 'Silver', value: 'silver', key: 'silver', color: '#c0c0c0', id: 16 },
  { label: 'Tan', value: 'tan', key: 'tan', color: '#e5dacb', id: 17 },
  { label: 'White', value: 'white', key: 'white', color: 'white', border: 'lightgray', id: 18 },
  { label: 'Yellow', value: 'yellow', key: 'yellow', color: '#f9e66d', id: 19 },
];

export const massUnits = [
  { label: 'g', key: 'g' },
  { label: 'kg', key: 'kg' },
  { label: 'lb', key: 'lb' },
  { label: 'oz', key: 'oz' },
];
export const dimensionUnits = [
  { label: 'cm', key: 'cm' },
  { label: 'm', key: 'm' },
  { label: 'in', key: 'in' },
  { label: 'ft', key: 'ft' },
  { label: 'mm', key: 'mm' },
  { label: 'yd', key: 'yd' },
];

export const paymentMethodOptions = [
  { label: 'Stripe', value: 'stripe', key: 'stripe' },
  { label: 'Paypal', value: 'paypal', key: 'paypal' },
];

// export const styleOptions = [
//   { label: 'Streetwear', value: 'streetwear', key: 'streetwear' },
//   { label: 'Sportswear', value: 'sportswear', key: 'sportswear' },
//   { label: 'Loungewear', value: 'loungewear', key: 'loungewear' },
//   { label: 'Goth', value: 'goth', key: 'goth' },
//   { label: 'Cute', value: 'cute', key: 'cute' },
//   { label: 'Chic', value: 'chic', key: 'chic' },
//   { label: 'Rave', value: 'rave', key: 'rave' },
//   { label: 'Pastel', value: 'pastel', key: 'pastel' },
//   { label: 'Bright', value: 'bright', key: 'bright' },
//   { label: 'Costume', value: 'costume', key: 'costume' },
//   { label: 'Lingerie', value: 'lingerie', key: 'lingerie' },
//   { label: 'Activewear', value: 'activewear', key: 'activewear' },
//   { label: 'Bag', value: 'bag', key: 'bag' },
//   { label: 'Shoes', value: 'shoes', key: 'shoes' },
//   { label: 'Kids', value: 'kids', key: 'kids' },
// ];
export const filters = [
  // {
  //   id: 'size',
  //   label: 'Size',
  //   type: 'SelectMultipleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_size'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'enum',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [],
  //   },
  // },
  {
    id: 'gender',
    label: 'Gender',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_gender'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...genderOptions],
    },
  },
  {
    id: 'menCategory',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_m_category_filter'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...menCategoriesOptions],
    },
  },
  {
    id: 'womenCategory',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_f_category_filter'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...womenCategoriesOptions],
    },
  },
  {
    id: 'kidsCategory',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_k_category_filter'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...kidsCategoriesOptions],
    },
  },
  {
    id: 'men_tops',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_men_sub_tops'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...menTopSubcategories],
    },
  },
  {
    id: 'men_bottoms',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_men_sub_bottoms'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...menBottomsSubcategories],
    },
  },
  {
    id: 'men_coats_jackets',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_men_sub_coats_jackets'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...menCoatsAndJacketsSubcategories],
    },
  },
  {
    id: 'men_footwear',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_men_sub_footwear'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...menFootwearSubcategories],
    },
  },
  {
    id: 'men_accessories',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_men_sub_accessories'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...menAccessoriesSubcategories],
    },
  },
  {
    id: 'men_nightwear',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_men_sub_nightwear'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...menNightwearSubcategories],
    },
  },
  {
    id: 'men_underwear',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_men_sub_underwear'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...menUnderwearSubcategories],
    },
  },
  {
    id: 'men_swimwear',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_men_sub_swimwear'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...menSwimwearSubcategories],
    },
  },
  {
    id: 'men_bags',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_men_sub_bags'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...menBagsSubcategories],
    },
  },
  {
    id: 'women_tops',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_women_sub_tops'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...womenTopSubCategories],
    },
  },
  {
    id: 'women_bottoms',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_women_sub_bottoms'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...womenBottomSubCategories],
    },
  },
  {
    id: 'women_dresses',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_women_sub_dresses'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...womenDressSubCategories],
    },
  },
  {
    id: 'women_coats_jackets',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_women_sub_coats_jackets'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...womenCoatsAndJacketSubCategories],
    },
  },
  {
    id: 'women_footwear',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_women_sub_footwear'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...womenFootWearsSubCategories],
    },
  },
  {
    id: 'women_accessories',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_women_sub_accessories'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...womenAccessoriesSubCategories],
    },
  },
  {
    id: 'women_nightwear',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_women_sub_nightwear'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...womenNightwareSubCategories],
    },
  },
  {
    id: 'women_underwear',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_women_sub_underwear'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...womenUnderwearSubCategories],
    },
  },
  {
    id: 'women_swimwear',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_women_sub_swimwear'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...womenSwimwearSubCategories],
    },
  },
  {
    id: 'women_bags',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_women_sub_bags'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...womenBagsSubCategories],
    },
  },
  {
    id: 'baby_girl_0_24',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_baby_girl_0_24'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...babyGirlSubcategories],
    },
  },
  {
    id: 'baby_boy_0_24',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_baby_boy_0_24'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...babyBoySubcategories],
    },
  },
  {
    id: 'toddler_girl_2_6',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_toddler_girl_2_6'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...toddlerGirlSubcategories],
    },
  },
  {
    id: 'toddler_boy_2_6',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_toddler_boy_2_6'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...toddlerBoySubcategories],
    },
  },
  {
    id: 'girl_youth_7_12',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_girl_youth_7_12'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...girlYouthSubcategories],
    },
  },
  {
    id: 'boy_youth_7_12',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_boy_youth_7_12'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...boyYouthSubcategories],
    },
  },
  {
    id: 'girl_teen_13_16',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_girl_teen_13_16'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...girlTeenSubcategories],
    },
  },
  {
    id: 'boy_teen_13_16',
    label: 'Sub Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_boy_teen_13_16'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...boyTeenSubcategories],
    },
  },
  {
    id: 'color',
    label: 'Color',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_color'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...colorOptions],
    },
  },
  {
    id: 'generic',
    label: 'Size',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_generic_size'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...genericSize],
    },
  },
  {
    id: 'menFootwear',
    label: 'Size',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_men_footwear_size'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...footwearMenSize],
    },
  },
  {
    id: 'womenFootwear',
    label: 'Size',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_women_footwear_size'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...footwearWomenSize],
    },
  },
  {
    id: 'size0_24m',
    label: 'Size',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_size_0_24m'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...size0_24m],
    },
  },
  {
    id: 'toddler2_6',
    label: 'Size',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_toddler2_6_size'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...toddler2_6],
    },
  },
  {
    id: 'size4_16',
    label: 'Size',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_size_4_16'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...size4_16],
    },
  },

  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  // Here is an example of multi-enum search filter.
  //
  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'multi-enum',

  //     // Optional modes: 'has_all', 'has_any'
  //     // Note: this is relevant only for schema type 'multi-enum'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'towels', label: 'Towels' },
  //       { key: 'bathroom', label: 'Bathroom' },
  //       { key: 'swimming_pool', label: 'Swimming pool' },
  //       { key: 'barbeque', label: 'Barbeque' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['size', 'brand', 'category'],
};
