import React from 'react';

import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';
import StaticPage from '../../containers/StaticPage/StaticPage';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import css from './RefundPolicy.module.css';

const RefundPolicy = () => {
  const { siteTwitterHandle, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="Refund Policy"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Sneakertime',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Refund Policy</h1>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>              
              <p>
              Our refund and return policy depends on the product purchased via <a href="yabbey.com">yabbey.com</a>.
              </p>
              <h2>Patterns</h2>
              <p>
              Patterns are digital files and we offer no returns or refunds. If you are unsatisfied with your purchase please contact us at info@yabbey.com and we will do our best to solve any matters arisen.
              </p>
              <h2>Kits returns</h2>
              <p>
                  Kits have a 14-day return policy, which means you have 14 days after receiving your item to request a return.
              </p>
              <p>
              To be eligible for a return, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. You’ll also need the receipt or proof of purchase.
              </p>
              <p>
              To start a return, you can contact us at <a href="mailto:info@yabbey.com">info@yabbey.com</a>. If your return is accepted, we’ll send you a return shipping label, as well as instructions on how and where to send your package. Items sent back to us without first requesting a return will not be accepted.
              </p>
              <p>You can always contact us for any return question at <a href="mailto:info@yabbey.com">info@yabbey.com</a>.</p>
              <h2>Kits refunds</h2>
              <p>We will notify you once we’ve received and inspected your return, and let you know if the refund was approved or not. If approved, you’ll be automatically refunded on your original payment method. Please remember it can take some time for your bank or credit card company to process and post the refund too.</p>
              <h2>
              Creations
              </h2>
              <p>
              Creations are items custom made by makers, which are third party sellers on yabbey.com. Please reach out to individual makers from whom you purchased your item for any questions about returns.
              </p>
              <h2>
              Damages and issues
              </h2>
              <p>
              Please inspect your order upon reception and contact us immediately if the item is defective, damaged or if you receive the wrong item, so that we can evaluate the issue and make it right.
              </p>
              <h2>
              Exceptions / non-returnable items
              </h2>
              <p>
              Unfortunately, we cannot accept returns on sale items or gift cards.
              </p>

            </div> 
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default RefundPolicy;
