import React, { useRef } from 'react';
import { bool, func, number, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import config from '../../../config';
import { FormattedMessage, useIntl, injectIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { numberAtLeast, requiredFieldArrayCheckbox, required } from '../../../util/validators';
const _ = require('lodash');
import {
  Form,
  FieldSelect,
  FieldTextInput,
  InlineTextButton,
  PrimaryButton,
} from '../../../components';
import { formatMoney } from '../../../util/currency';
import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';

import css from './ProductOrderForm.module.css';
import FieldSelectModern from '../../FieldSelectModern/FieldSelectModern';
import { capitalize } from '../../../util/functions';
import { paypalDisabled } from '../../../config/config';
import { useLayoutEffect } from 'react';
import { useState } from 'react';

const renderForm = formRenderProps => {
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    formId,
    currentStock,
    hasMultipleDeliveryMethods,
    listingId,
    listingVariants,
    isOwnListing,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    values,
    listing,
    price,
    invalid,
    disabled,
    paymentMethodsAvailable,
    errors,
  } = formRenderProps;
  const sizeConfigOptions = [
    ...config.custom.genericSize,
    ...config.custom.footwearMenSize,
    ...config.custom.footwearWomenSize,
    ...config.custom.size0_24m,
    ...config.custom.toddler2_6,
    ...config.custom.size4_16
  ];

  const { publicData } = listing?.attributes;
  const { color, size } = publicData ?? {};
  const colorOptions = color?.map((item, index) =>
    config.custom.colorOptions?.find(color => color.key === item)
  );
  const sizeOptions = size?.map((item, index) =>
    sizeConfigOptions?.find(size => size.key === item)
  );

  const handleOnChange = formValues => {
    const {
      quantity: quantityRaw = 1,
      deliveryMethod = 'shipping',
      payment_method,
    } = formValues.values;

    if (paypalDisabled) {
      if (!payment_method?.key) {
        return;
      }
    } else {
      const validPaymentMethodSelected = payment_method?.key
        ? paymentMethodsAvailable.map(p => p.key).includes(payment_method.key)
        : null;

      if (!validPaymentMethodSelected) {
        return;
      }
    }

    // console.log('values', formValues.values);
    const quantity = Number.parseInt(quantityRaw, 10);
    const isBrowser = typeof window !== 'undefined';
    if (
      isBrowser &&
      quantity &&
      formValues.values?.color &&
      formValues.values?.size &&
      deliveryMethod &&
      !fetchLineItemsInProgress
    ) {
      onFetchTransactionLineItems({
        orderData: { quantity, deliveryMethod },
        listingId,
        isOwnListing,
      });
    }
  };

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = e => {
    for (const key in values) {
      if (!values[key].key) {
        e.preventDefault();
        return;
      }
    }

    handleSubmit({ e });
  };

  const { payment_method, ...restValues } = values;
  const validPaymentMethodSelected = payment_method?.key
    ? paymentMethodsAvailable.map(p => p.key).includes(payment_method.key)
    : null;

  // console.log({
  //   validPaymentMethodSelected,
  //   payment_method,
  // });

  let objKey = Object.keys(restValues);

  const breakdownData = {};
  const showBreakdown =
    values?.color &&
    values?.size &&
    validPaymentMethodSelected &&
    !fetchLineItemsInProgress &&
    !fetchLineItemsError;

  const stripeEnabled = paymentMethodsAvailable.find(p => p.key === 'stripe');

  const breakdown = showBreakdown ? (
    <div className={css.breakdownWrapper}>
      <h3>
        <FormattedMessage id="ProductOrderForm.breakdownTitle" />
      </h3>
      <div>
        {objKey.map(i => (
          <div style={{ display: 'flex' }} key={i}>
            <span className={css.itemLabel}>{capitalize(i)}</span>
            <span className={css.itemLabel} style={{ textAlign: 'right', paddingLeft: '30px' }}>
              {values[i].label}
            </span>
          </div>
        ))}
      </div>
      <EstimatedCustomerBreakdownMaybe
        unitType={config.lineItemUnitType}
        breakdownData={breakdownData}
        lineItems={lineItems}
      />
    </div>
  ) : null;

  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser();
  };

  const contactSellerLink = (
    <InlineTextButton onClick={onClickContactUser}>
      <FormattedMessage id="ProductOrderForm.finePrintNoStockLinkText" />
    </InlineTextButton>
  );
  const quantityRequiredMsg = intl.formatMessage({ id: 'ProductOrderForm.quantityRequired' });

  const hasStock = currentStock && currentStock > 0;
  const quantities = hasStock ? [...Array(currentStock).keys()].map(i => i + 1) : [];
  const hasNoStockLeft = typeof currentStock != null && currentStock === 0;
  const hasOneItemLeft = typeof currentStock != null && currentStock === 1;
  const submitInProgress = fetchLineItemsInProgress;
  const submitDisabled =
    invalid ||
    disabled ||
    isOwnListing ||
    // !validPaymentMethodSelected ||
    Object.keys(errors).length > 0 ||
    fetchLineItemsInProgress ||
    fetchLineItemsError;

  // ||
  // Object.keys(values).length != listingVariants.length;

  const bookingNotAllowedWhenPaypalDisabled = paypalDisabled ? (
    !stripeEnabled && listing?.id?.uuid && typeof listing?.currentStock != 'undefined' ? (
      <p
        style={{
          color: 'red',
          lineHeight: '1.4',
        }}
      >
        {isOwnListing
          ? 'This product can not be ordered as you have not yet set up the bank account details.'
          : 'You cannot order this product as the provider has not yet set up the bank account details.'}
      </p>
    ) : null
  ) : null;

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormSpy subscription={{ values: true }} onChange={handleOnChange} />
      {bookingNotAllowedWhenPaypalDisabled ? (
        bookingNotAllowedWhenPaypalDisabled
      ) : (
        <>
          {colorOptions && !isOwnListing ? (
            <FieldSelectModern
              id="color"
              name="color"
              label={intl.formatMessage({ id: 'ProductOrderForm.color' })}
              options={colorOptions}
              className={css.quantityField}
              custom={true}
              placeholder={intl.formatMessage({ id: 'ProductOrderForm.colorPlaceholder' })}
              validate={required(intl.formatMessage({ id: 'ProductOrderForm.colorRequired' }))}
              onFieldChange={() => {
                if (paypalDisabled) {
                  formApi.change('payment_method', {
                    label: 'Stripe',
                    value: 'stripe',
                    key: 'stripe',
                  });
                }
              }}
            />
          ) : null}

          {sizeOptions && !isOwnListing ? (
            <FieldSelectModern
              id="size"
              name="size"
              label={intl.formatMessage({ id: 'ProductOrderForm.size' })}
              options={sizeOptions}
              className={css.quantityField}
              placeholder={intl.formatMessage({ id: 'ProductOrderForm.sizePlaceholder' })}
              validate={required(intl.formatMessage({ id: 'ProductOrderForm.sizeRequired' }))}
            />
          ) : null}
          {listingVariants && !isOwnListing && listingVariants?.length >= 1
            ? listingVariants?.map((variant, idx) => (
                <FieldSelectModern
                  id={`${idx}.variant`}
                  className={css.quantityField}
                  name={`${variant.name}`}
                  disabled={!hasStock}
                  label={_.startCase(_.toLower(variant.name))}
                  // validate={numberAtLeast(quantityRequiredMsg, 1)}
                  validate={requiredFieldArrayCheckbox(
                    'Select ' + _.startCase(_.toLower(variant.name))
                  )}
                  placeholder={'Select ' + _.startCase(_.toLower(variant.name))}
                  options={variant.options.map(optionName => ({
                    label: optionName,
                    value: optionName,
                    key: optionName,
                  }))}
                ></FieldSelectModern>
              ))
            : null}
          <FieldSelectModern
            className={css.quantityField}
            id="method"
            name="method"
            label={intl.formatMessage({ id: 'ProductOrderForm.ProductOrderForm.methodLabel' })}
            placeholder={intl.formatMessage({
              id: 'ProductOrderForm.ProductOrderForm.methodPlaceholder',
            })}
            options={config.custom.orderOptions}
            validate={required(
              <FormattedMessage id="ProductOrderForm.ProductOrderForm.methodRequiredMessage" />
            )}
          />
          {paypalDisabled ? null : paymentMethodsAvailable.length > 0 ? (
            <FieldSelectModern
              id="payment_method"
              name="payment_method"
              className={css.quantityField}
              label={intl.formatMessage({ id: 'ProductOrderForm.paymentMethod' })}
              options={paymentMethodsAvailable}
              validate={requiredFieldArrayCheckbox(
                intl.formatMessage({
                  id: 'ProductOrderForm.paymentMethodRequired',
                })
              )}
              placeholder={intl.formatMessage({ id: 'ProductOrderForm.paymentMethodPlaceholder' })}
            />
          ) : null}

          {breakdown}

          {/* <div className={css.orderBreakdown}>
        <h3>Order Breakdown</h3>

        <div>
          {objKey.map(i => (
            <div style={{ display: 'flex' }}>
              <span className={css.itemLabel}>{i}</span>
              <span className={css.itemLabel} style={{ textAlign: 'right', paddingLeft: '30px' }}>
                {values[i].key}
              </span>
            </div>
          ))}
        </div>

        <div className={css.lineItem}>
          <span className={css.itemLabel}>Price:</span>
          <span className={css.itemValue}>{formatMoney(intl, price)}</span>
        </div>
      </div> */}
          <div className={css.submitButton}>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              {hasStock ? (
                <FormattedMessage id="ProductOrderForm.ctaButton" />
              ) : (
                <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
              )}
            </PrimaryButton>
          </div>
          <p className={css.finePrint}>
            {hasStock ? (
              <FormattedMessage id="ProductOrderForm.finePrint" />
            ) : showContactUser ? (
              <FormattedMessage
                id="ProductOrderForm.finePrintNoStock"
                values={{ contactSellerLink }}
              />
            ) : null}
          </p>
        </>
      )}
    </Form>
  );
};

const ProductOrderForm = props => {
  const intl = useIntl();
  console.log('rendering...', props.listing);
  const { price, currentStock, pickupEnabled, shippingEnabled } = props;

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.
  // if (!pickupEnabled && !shippingEnabled) {
  //   return (
  //     <p className={css.error}>
  //       <FormattedMessage id="ProductOrderForm.noDeliveryMethodSet" />
  //     </p>
  //   );
  // }

  if (!price) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingPriceMissing" />
      </p>
    );
  }
  if (price.currency !== config.currency) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingCurrencyInvalid" />
      </p>
    );
  }
  const hasOneItemLeft = currentStock && currentStock === 1;
  const quantityMaybe = hasOneItemLeft ? { quantity: '1' } : {};
  const singleDeliveryMethodAvailableMaybe =
    shippingEnabled && !pickupEnabled
      ? { deliveryMethod: 'shipping' }
      : !shippingEnabled && pickupEnabled
      ? { deliveryMethod: 'pickup' }
      : {};
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
  const initialValues = { ...quantityMaybe, ...singleDeliveryMethodAvailableMaybe };

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      {...props}
      intl={intl}
      render={renderForm}
    />
  );
};

ProductOrderForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  isOwnListing: false,
  lineItems: null,
  fetchLineItemsError: null,
};

ProductOrderForm.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,

  // line items
  lineItems: propTypes.lineItems,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // other
  onContactUser: func,
};
const ProductOrderFormNew = compose(injectIntl)(ProductOrderForm);
export default ProductOrderFormNew;
